@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@300;400;500;600;700;800&display=swap');
:root {
    --main-bg: #1F2734;

    --text-color: #fff;
    --theme-color: #1675FF;
    --theme-secondary-color: rgba(22, 117, 255, 0.25);

    --theme-bg-color: rgba(52, 120, 253, 0.15);
    --dark-bg: rgba(22, 117, 255, .1);

    --default-border-radius: 15px;
    --secondary-border-radius: 9.786px;

    --default-font-size: 14px;
}

html, body, * {
    font-family: 'Lexend', sans-serif;
    scroll-behavior: smooth;
    letter-spacing: -2%;
}

body {
    background: var(--main-bg);
    color: var(--text-color);
    margin: 0;
    padding: 0;
    max-width: 100%;
    overflow-x: hidden;
}

* ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    cursor: pointer;
}

* ::-webkit-scrollbar-thumb {
    background: var(--theme-color);
    border-radius: 8px;
}

* ::-webkit-scrollbar-track {
    background: var(--dark-bg);
}

*, *::before, *::after {
    box-sizing: border-box;
}

.bg-filterbr {
    backdrop-filter: blur(12.5px); 
}

.pf-theme path {
    fill: var(--theme-color);
}

.pf-theme {
    margin-top: 1.6px;
}

//navbar
.storefront-navbar {
    margin: 40px 0;
    background: var(--theme-secondary-color);
    padding: 15px 17.5px;
    border-radius: var(--default-border-radius);
}

.storefront-navbar-content {
    display: flex;
    justify-content: space-between;
    height: fit-content;
    align-items: center;
    width: 100%;
}

.storefront-navbar button {
    box-shadow: none;
}

.storefront-navbar-branding:hover {
    cursor: pointer;
}

.storefront-navbar > * {
    display: flex;
}

.storefront-navbar-right {
    justify-content: right;
    display: flex;
    align-items: center;
}

.storefront-navbar-right > .divider-svg {
    margin: 0 10px;
}

.storefront-navbar-right:last-child {
    margin-right: 0;
}

.storefront-navbar-options {
    flex: 1;
    margin: 0 24px;
    display: flex;
    align-items: center;
}

.storefront-navbar-options > svg {
    margin-right: 18px;
}

.storefront-navbar-branding-img {
    height: 2.5rem;
}

.storefront-navbar-branding-img:hover {
    cursor: pointer;
}

.storefront-navbar-branding-text {
    font-size: 28px;
    font-weight: 700;
}

.storefront-navbar-options {
    // flex: 1;
    // margin-left: 24px;
    // margin-left: 260px;
}

.storefront-navbar-option {
    color: #fff;
    text-decoration: none;
    line-height: 24px;
    margin: 0 8px;
    font-size: 15px;
    font-weight: 500;
    border-bottom: 2px solid transparent;
}

.sno-image {
    display: flex;
    align-items: center;
}

.sno-image > img {
    width: 18px;
    margin-left: 8px;
}

.storefront-navbar-option-active {
    font-weight: 500;
    border-bottom: 2px solid var(--theme-color);
}

.btn {
    border: none;
    color: var(--text-color);
    font-size: 14px;
    font-weight: 600;
    padding: 6px 8.5px;
    border-radius: 8px;
    border: 1.5px solid transparent;
}

button * {
    text-decoration: none;
}

.btn-snb {
    padding: 10px 15px;
    font-size: 13px;
    background: var(--theme-color);
    margin: 0 8px 0 8px;
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075);
}

.btn-snb > svg {
    margin-right: 8px;
}

.btn-snb-outline {
    background: transparent;
    border: 1.5px solid var(--theme-color);
    color: var(--theme-color);
}

.btn-snb-outline-none {
    background: transparent;
    border: 1.5px solid transparent;
    color: var(--theme-color);
}

.btn-snb:hover:not(:disabled) {
    cursor: pointer;
}

.storefront-index-hero {
    text-align: center;
    width: 100%;
    border-radius: var(--default-border-radius);
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: rgba(22, 117, 255);
    position: relative;
    z-index: 10;
}

.storefront-index-hero::before {
    content: "";
    background: url(https://i.imgur.com/MNPBXEH.jpg), lightgray 0px -136.717px / 100% 349.546% no-repeat;
    background-size: cover;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    opacity: 0.20;
    border-radius: var(--default-border-radius);
    z-index: -1;
}

.storefront-index-hero-left {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 60px 50px;
}

.storefront-index-hero-welcome-text {
    text-align: left;
}

.storefront-ihero-welcome-text2 {
    display: flex;
    flex-direction: column;
}

.storefront-ihero-welcome-text2 > .stre-text {
    margin-left: auto;
    margin-top: 8px;
}

.storefront-index-hero-p {
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.4px; 
    font-size: 20px;
    text-align: left;
    width: 75%;
    margin-left: auto;
}

.lg-white-btn {
    width: 75%;
    margin-left: auto;
    background: #fff;
    color: var(--theme-color);
    padding: 15px 20px;
    font-size: 18px;
    margin-top: 12px;
}

.storefront-txt-sx5 {
    letter-spacing: -0.4px;
    font-size: 20px;
    text-transform: uppercase;
    margin-left: auto;
    font-weight: 500;
    margin-bottom: 12px;
}

.storefront-index-hero-right {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 60px 50px;
}

.storefront-index-hero-img {
    width: 25%;
    border-radius: 6px;
    margin-top: 36px;
    // margin-bottom: 36px;
}

.storefront-index-hero-title {
    font-size: 30px;
    display: block;
    font-weight: 700;
}

.storefront-index-hero-text {
    color: #999;
    font-size: 17.5px;
    margin-top: 8px;
}

.storefront-index-search {
    display: flex;
    margin-top: 36px;
    width: 100%;
}

.storefront-search-container {
    display: flex;
    width: 100%;
}

.storefront-search-container > .storefront-input-container {
    margin-left: 1.5%;
    width: 49.25%;
}

.storefront-search-container > .storefront-input-container:first-of-type {
    margin-left: 0;
}

.storefront-input-container {
    background: transparent;
    // border: 1.5px solid rgba(255, 255, 255, 0.1);
    width: 821px;
    display: flex;
}

.storefront-input-container-sm {
    width: 366px;
}

.storefront-input-icon {
    padding: .75rem 0;
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    background: var(--theme-secondary-color);
    color: var(--text-color);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.storefront-input {
    margin-left: 8px;
    background: transparent;
    
    width: 821px;

    border: 2px solid #343C51;
    color: #fff;
    border-radius: 10px;

    letter-spacing: -0.32px;
    font-size: 16px;
    
    padding: 8px 12px;
    font-weight: 400;
    line-height: normal; 
    opacity: 0.35;

    height: 40px;
}

.storefront-input::placeholder {
    color: rgba(255, 255, 255);
    opacity: 1;
}

.storefront-search-input::placeholder {
    color: #fff;
}

.storefront-input:focus::placeholder {
    color: rgba(255, 255, 255, .35);
}

.storefront-input:focus {
    color: rgba(255, 255, 255, .75);
    opacity: 1;
    border-color: rgba(255, 255, 255, .35);
}

.storefront-category-select {
    background: var(--dark-bg);
    border: 1.5px solid rgba(255, 255, 255, 0.1);
    padding: .75rem 1.25rem;
    height: calc(1.5em + 1.5rem + 2px);
    border-radius: .5rem;
    font-size: 14.5px;
    margin-left: 12px;
    border: 1px solid var(--theme-color);
    width: 49%;
    color: #fff;
    line-height: 1.5;
}

.storefront-items > .storefront-items-row:first-of-type {
    margin-top: 36px;
}

.storefront-items-row > .storefront-item:first-of-type {
    margin-left: 0;
}

.storefront-items-row > .storefront-item:last-of-type {
    margin-right: 0;
}

.storefront-items-row {
    width: 100%;
    display: flex;
    justify-content: center;
}

.storefront-items-row > .storefront-item:first-of-type {
    // margin-left: 0;
}

.storefront-item {
    background: var(--theme-secondary-color);
    width: 24.25%;
    border-radius: var(--default-border-radius);
    transition: .35s;
    border: 1px solid transparent;
    // margin-left: 2.66%;
    margin: 5px 7.5px;
    display: flex;
    flex-direction: column;
    padding: 12px;
}

.storefront-item:hover {
    // cursor: pointer;
    // background: rgba(34, 34, 34);
    // transition: .35s;
}

.storefront-item-image {
    width: 100%;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}

.storefront-item-content {
    padding-top: 6px;
    min-height: 50px;
    margin-top: auto;
}

.storefront-item-name {
    font-weight: 400;
    font-size: 16px;
    letter-spacing: -0.32px;
    text-align: center;
    padding: 8px 0;
}

.storefront-item-price {
    font-weight: 600;
    font-size: 18px;
    text-transform: capitalize;
    margin-top: 4px;
}

.storefront-item-button {
    background: var(--theme-color);
    border: 1px solid var(--theme-color);
    width: 100%;
    text-transform: capitalize;
    letter-spacing: normal;
    padding: 8px;
    border-radius: 9.786px;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
    line-height: 1.5;
    
    font-size: 14px;
    letter-spacing: -0.28px;
    font-weight: 400;

    display: flex;
    align-items: center;
    justify-content: center;

    margin-top: 8px;
}

.storefront-btn-item-price {
    // color: rgba(255, 255, 255, .95);
    border-left: 2px solid rgba(255, 255, 255, .2);
    padding-left: 16px;
    margin-left: 16px;
}

.btn:hover:not(:disabled) {
    cursor: pointer;
}

.storefront-item-button {
    transition: .25s;
}

.storefront-item-button:hover {
    transition: .25s;
    // background: rgba(110, 46, 204, .75);
    background: var(--theme-color);
}

.storefront-error {
    width: 100%;
    text-align: center;
}

.storefront-error-icon {
    font-size: 38px;
    color: rgba(255, 0, 0, .95);
}

.storefront-error-title {
    font-size: 22px;
    font-weight: 700;
}

.storefront-error-text {
    font-size: 15px;
    color: rgba(255, 255, 255, .8);
}

.auth-form-page {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.signup-page {
    width: 100%;
    display: flex;
}

.signup-form {
    display: flex;
    flex-direction: column;
}

.signup-content {
    padding: 24px 28px;
    background: rgba(22, 117, 255, 0.10);
    border: 1px solid var(--theme-color);
    border-radius: 10px;
    margin-left: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.auth-form-subtitle {
    white-space: nowrap;
    text-overflow: none;
    flex-wrap: nowrap;
}

.signup-reasons > .signup-reason:first-of-type {
    margin-top: 0;
}

.signup-reason {
    display: flex;
    align-items: center;
    margin-top: 15px;
}

.signup-reason-icon {
    padding: 0 4px;
}

.signup-reason-text {
    color: rgba(255, 255, 255, .75);
    letter-spacing: -0.36px;
    font-weight: 400;
    font-size: 18px;
}

.pw-change-reasons {
    margin-top: 11px;
}

.pw-change-reason-text {
    letter-spacing: -0.28px; 
    font-weight: 400;
    font-size: 14px;
    color: rgba(255, 255, 255, .75);
}

.referral-reason-text {
    letter-spacing: -0.32px; 
    font-weight: 400;
    font-size: 16px;
    color: rgba(255, 255, 255, .75);
}

.signup-offer {
    background: rgba(255, 203, 61, 0.25);
    border: 1px solid #FFCB3D;
    border-radius: 10px;
    padding: 28px 40px;
}

.signup-offer-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.signup-offer-header-text {
    color: #FFCB3D;
    letter-spacing: -0.36px;
    font-size: 18px;
    font-weight: 400;
}

.signup-offer-header-textsm {
    letter-spacing: -0.24px;
    color: rgba(255, 255, 255, .5);
    font-size: 12px;
    font-weight: 400;
}

.signup-offer-content {
    font-weight: 400; 
    letter-spacing: -0.36px;
    font-size: 16px;
    white-space: nowrap;
    text-overflow: none;
    flex-wrap: nowrap;
}

.auth-form {
    width: 426px;
    padding: 24px 28px;
    background: rgba(22, 117, 255, 0.10);
    border: 1px solid var(--theme-color);
    border-radius: 10px;
}

.auth-form-blw {
    width: 426px;
    height: 61px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--theme-color);
    border-radius: 10px;
    margin-top: 20px;
    font-weight: 500;
    letter-spacing: -0.28px;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.75);
}

.auth-form-blw > a {
    font-weight: 600;
    letter-spacing: -0.28px;
}

.auth-form > * {
    display: block;
}

.auth-form-title {
    letter-spacing: -0.52px;
    font-weight: 500;
    font-size: 26px; 
    text-align: left;
}

.auth-form-subtitle {
    letter-spacing: -0.36px;
    font-weight: 400;
    font-size: 18px;
    opacity: .75;
}

.auth-form-termstext {
    color: rgba(255, 255, 255, 0.50);
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.28px;
    text-align: center;
    margin-top: 14px;
    width: 75%;
    margin-left: 12.5%;
}

.auth-form-termstext a {
    color: rgba(255, 255, 255, 0.75);
    font-size: 14px;
    font-weight: 700;
    letter-spacing: -0.28px;
}

.auth-form-inputs {
    margin-top: 24px;
}

.auth-form-input {
    width: 100%;
    border: none;
    background: var(--dark-bg);
    color: #fff;
    padding: 12px 16px;
    font-size: 18px;
    border-radius: .375rem;
    border: 1px solid var(--theme-color);
    font-size: .9375rem;
    margin-bottom: 14px;
}

.auth-form-inputs > .auth-form-input:last-of-type {
    margin-bottom: 0;
}

.auth-form-btn {
    background: var(--theme-color);
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075);
    width: 100%;
    padding: 10px;

    font-size: 14px;
    letter-spacing: -0.28px;
    font-weight: 500;

    transition: .25s;
    text-transform: none;
    border-radius: 9.786px;
    height: 43px;
}

.auth-form-inputs > .auth-input:first-of-type {
    margin-top: 0;
}

.auth-input {
    display: flex;
    margin-top: 12px;
}

.auth-input-icon {
    width: 45px;
    height: 40px;
    background: rgba(22, 117, 255, 0.25);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.auth-input-icon svg {
    width: 24px;
    height: 24px;
}

.auth-input-field {
    width: calc(100% - 12px);
    margin-left: 12px;
    background: transparent;
    border: 2px solid rgba(255, 255, 255, 0.10); 
    color: rgba(255, 255, 255, .5);
    border-radius: 10px;
    letter-spacing: -0.32px;
    font-size: 16px;
    padding: 10px;
    letter-spacing: -0.32px; 
    font-weight: 400;
    line-height: normal;
    height: 40px;
}

.auth-input-field:focus {
    border-color: var(--theme-color);
    color: #fff;
}

.referral-input-field {
    color: var(--theme-color);
    font-weight: 500;
    border: 2px solid var(--theme-color);
    display: flex;
    align-items: center;
}

.auth-form-btn svg {
    margin-right: 8px;
}

.auth-form-btn:hover:not(:disabled) {
    background: rgba(0, 85, 255, .75);
    transition: .25s;
}

.auth-form-btn:disabled {
    background: rgba(0, 85, 255, .75);
    color: rgba(255, 255, 255, .75);
}

.btn-nrm {
    background: var(--theme-color);
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075);
    width: fit-content;
    padding: 8px 12px;
    font-size: 16px;
    transition: .25s;
    text-transform: none;
}

.dflx {
    display: flex;
    align-items: center;
}

.btn-nrm-sm {
    font-size: 14px;
}

.btn-nrm-xsm {
    font-size: 12px;
    display: flex;
    align-items: center;
}

.btn-nrm svg {
    margin-right: 8px;
}

.btn-nrm:hover:not(:disabled) {
    background: rgba(0, 85, 255, .75);
    transition: .25s;
}

.btn-nrm:disabled {
    background: rgba(0, 85, 255, .75);
    color: rgba(255, 255, 255, .75);
}

.auth-form-sub {
    width: 100%;
    text-align: center;
    padding: 10px;
}

.auth-form-sub a {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.28px;
    color: var(--text-color);
    text-decoration: none;
}

.auth-form-captcha {
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 14px;
    margin-top: 14px;
}

.auth-form-error {
    background: #Be253a;
    color: #fff;
    padding: 12px;
    margin-top: 8px;
    border-radius: 6px;
}

.form-info {
    background: rgba(52, 120, 253, .5);
    border: 1px solid var(--theme-color);
    margin-top: 8px;
    padding: 12px;
    border-radius: 8px;
    color: var(--theme-color);
}

//user dashboard
.userhome {
    width: 100%;
}

.user-home-hero {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
}

.user-home-hero-nav {
    width: 49%;
    margin-left: 1%;
}

.userhome-hero-item {
    width: 100%;
    background: var(--dark-bg);
    border-radius: 8px;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    border: 2px solid transparent;
    transition: .25s;
}

.user-home-hero-nav > .userhome-hero-item:first-of-type {
    margin-bottom: 16px;
}

.userhome-hero-item:hover {
    cursor: pointer;
    border: 2px solid var(--theme-color);
    transition: .25s;
}

.user-home-hero-options {
    width: 100%;
    padding: 12px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.user-home-hero-options > .btn:last-of-type {
    margin-left: 4%;
}

.user-home-hero-btn-lg {
    background: rgba(22, 117, 255, 0.10); 
    border-radius: 9.786px; 
    height: 43px;
    width: calc(100% - 51px);
}

.user-home-hero-btn-lgout {
    background: rgba(213, 31, 38, 0.25); 
    border-radius: 9.786px;
    height: 43px;
    width: 43px;
    margin-left: 8px;
}

.user-home-hero-content-vls {
    width: 100%;
    display: flex;
}

.user-home-hero-vls-container {
    margin-bottom: auto;
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid rgba(22, 117, 255, .25);
}

.user-home-vls {
    display: flex;
    font-size: 15.44px;
    font-weight: 500;
    letter-spacing: -0.309px; 
    padding: 12px 0;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.uh-vls-title {
    display: flex;
    align-items: center;
    color: var(--theme-color);
}

.uh-vls-value {
    margin-left: 6px;
    color: var(--text-color);
}

.user-home-details-title {
    font-size: 17.645px;
    letter-spacing: -0.353px;
    font-weight: 500;
    border-bottom: 1px solid rgba(22, 117, 255, .25);
    padding: 7px 0 15px 0;
}

.user-support-new-text {
    font-size: 14px;
    letter-spacing: -0.82px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.75);
    padding: 21px 0 0 0;
}

.user-support-existing-title {
    font-size: 17.645px;
    letter-spacing: -0.353px;
    font-weight: 500;
    padding: 7px 0 15px 0;
}

.user-home-hero-content {
    background: var(--dark-bg);
    border-radius: var(--default-border-radius);
    padding: 12px 24px;
    border-top: 11px solid var(--theme-color);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 41.5%;
}

.user-settings-edit-details {
    background: var(--dark-bg);
    border-radius: var(--default-border-radius);
    padding: 12px 24px;
    padding-bottom: 23px;
    border-top: 11px solid transparent;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 49.25%;
}

.user-settings-edit-password {
    margin-left: 1.5%;
    width: 49.25%;
    background: var(--dark-bg);
    border-top: 11px solid var(--theme-color);
    padding: 12px 24px;
    border-radius: var(--default-border-radius);
    min-height: 387px;
}

.user-settings-submit {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    margin-top: 19px;
}

.btn-settings-submit {
    width: 165px;
    height: 43px;
    background: var(--theme-color);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    letter-spacing: -0.28px;
    font-weight: 500;
}

.btn-settings-submit svg {
    margin-right: 6px;
}

.user-home-recent-orders {
    margin-left: 1.5%;
    width: 57%;
    background: var(--dark-bg);
    border-top: 11px solid transparent;
    padding: 12px 24px;
    border-radius: var(--default-border-radius);
    min-height: 534px;
}

.user-home-recent-orders-title {
    display: flex;
    justify-content: space-between;
}

.user-home-recent-orders-title-text {
    letter-spacing: -0.353px;
    font-weight: 500;
    font-size: 17.645px;
}

.user-home-recent-orders-title-link {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.28px;
}

.user-home-recent-orders-title-link svg {
    margin-left: 8px;
}

.user-home-recent-orders-title-link:hover {
    cursor: pointer;
}

.user-tickets-welcome {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}

.user-tickets-content {
    flex: 1;
}

.user-home-welcome {
    margin: 32px 0;
}

.user-home-welcome-title {
    letter-spacing: -0.48px; 
    font-weight: 500;
    font-size: 24px;
}

.user-home-welcome-title svg {
    color: var(--theme-color);
}

.user-orders-header {
    display: flex;
    justify-content: space-between;
    margin: 32px 0;
}

.user-orders-header-text {
    letter-spacing: -0.48px; 
    font-weight: 500;
    font-size: 24px;
}

.user-orders-filter-status-container {
    display: flex;
    align-items: center;
}

.user-orders-filter-status-text {
    letter-spacing: -0.32px;
    font-weight: 400;
    font-size: 16px;
    opacity: 0.5;
    margin: 0 16px;
}

.user-orders-filter-input {
    width: 143px;
    height: 40px;
    font-size: 16px;
    letter-spacing: -0.32px;
    font-weight: 400;
    opacity: 1;
    border: 2px solid rgba(52, 60, 81, .35);
    color: rgba(255, 255, 255, .35);
}

.option-completed {
    color: #1AD77A;
}

.option-awaiting_payment {
    color: #FFCB3D;
}

.option-processing {
    color: #1675FF;
}

.option-cancelled {
    color: #D51F26;
}

.userorders-table-status-awaiting_staff_reply {
    color: #FFCB3D;
}

.user-orders-header-options {
    display: flex;
}

.user-orders-error {
    margin-top: 128px;
}

.text-underline {
    text-decoration: underline;
    text-decoration-color: var(--theme-color);
}

.user-home-welcome-text {
    color: #999;
    font-weight: 600;
    font-size: 14px;
    margin-top: -4px;
}

.user-home-hero > .userhome-hero-item:first-of-type {
    margin-left: 0;
}

.userhome-hero-title {
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 600;
    color: rgba(255, 255, 255, .95);
}

.userhome-hero-value {
    color: var(--theme-color);
    font-size: 32px;
    font-weight: 700;
}

.userhome-hero-icon {
    position: absolute;
    z-index: 1;
    opacity: .45;
    font-size: 130px;
    color: rgba(0, 0, 0, .4);
    left: -25px;
}

.userhome-hero-content {
    position: relative;
    z-index: 50;
    padding: 28px;
}

.username-hero-link {
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    text-decoration: none;
    transition: transform ease 300ms;
    display: flex;
    height: 40px;
    padding-top: 15px;
    align-items: center;
}

.username-hero-link > svg {
    margin-right: 8px;
    color: var(--theme-color);
}

.username-hero-link:hover {
    cursor: pointer;
    transform: translate(0, -10px);
}

.user-home-content {
    background: var(--dark-bg);
    margin-top: 36px;
    border-radius: 8px;
    margin-bottom: 36px;
}

.user-home-content-title {
    padding: 16px;
    font-size: 22px;
    font-weight: 700;
    border-bottom: 1px solid rgba(255, 255, 255, .05);
}

.user-home-content-title svg {
    margin-right: 12px;
}

.user-balance-hero {
    display: flex;
    align-items: center;
    width: 100%;
}

.user-balance-total {
    width: 25%;
    background: var(--theme-color);
    padding: 27.5px 24px;
    border-radius: var(--default-border-radius);
    border: 1px solid transparent;
    height: 154px;
}

.user-balance-total-title {
    display: flex;
    align-items: center;
}

.user-balance-total-title-icon {
    display: flex;
}

.user-balance-total-title-text {
    letter-spacing: -0.4px;
    font-weight: 500;
    font-size: 20px;
    margin-left: 10px;
}

.user-balance-total-content {
    display: flex;
    margin-top: 18px;
}

.user-balance-total-icon {
    font-size: 24.248px; 
    letter-spacing: -0.485px;
    font-weight: 500;
}

.user-balance-total-value {
    letter-spacing: -0.913px; 
    font-weight: 600;
    font-size: 45.643px;
    margin-left: 4px;
    margin-top: -4px;
}

.user-balance-order {
    width: 73.5%;
    margin-left: 1.5%;
    border: 1px solid var(--theme-color);
    border-radius: var(--default-border-radius);
    height: 154px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 27px 34px;
}

.user-balance-order-btn {
    margin-top: 16px;
    height: 40px!important;
}

.user-balance-order-btn svg {
    margin-right: 0!important;
}

.user-balance-order-input {
    width: 200px;
}

.user-balance-order-input > .auth-input-icon > svg {
    padding: 3.5px;
}

.user-balance-order-divider {
    margin: 0 32px;
}

.user-balance-order-title {
    font-size: 20px;
    font-weight: 500;
    letter-spacing: -0.4px;
}

.user-balance-order-payment-methods {
    min-height: 40px;
    margin-top: 16px;
    display: flex;
    align-items: center;
}

.user-balance-order-payment-methods > .user-balance-order-payment-method:first-of-type, .user-checkout-order-payment-methods > .user-checkout-order-payment-method:first-of-type {
    margin-left: 0;
}

.user-balance-order-payment-method {
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 115px;
    border: 1px solid var(--theme-color);
    border-radius: 10px;
    margin-left: 25px;
}

.user-balance-order-payment-method:hover {
    cursor: pointer;
}

.user-balance-order-payment-method-txt {
    font-size: 14px;
    letter-spacing: -0.28px;
    font-weight: 500;
}

.user-balance-order-payment-method-selected {
    background: var(--theme-color);
}

.user-checkout-order-payment-methods {
    min-height: 50px;
    margin-top: 23px;
    display: flex;
    align-items: center;
}

.user-checkout-order-payment-method {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 130px;
    border: 1px solid var(--theme-color);
    border-radius: 10px;
    margin-left: 25px;
}

.user-checkout-order-payment-method:hover {
    cursor: pointer;
}

.user-balance-reasons {
    background: var(--dark-bg);
    border-radius: var(--default-border-radius);
    padding: 26px 36px;
    margin-top: 25px;
}

.user-balance-reasons-title {
    letter-spacing: -0.4px; 
    font-weight: 600; 
    font-size: 20px;
    color: var(--theme-color);
}

.user-balance-reasons-subtitle {
    margin-top: 17px;
    color: rgba(255, 255, 255, .75);
    letter-spacing: -0.36px; 
    font-weight: 500;
    font-size: 18px;
}

.user-balance-reasons-content {
    margin-top: 33px;
}

.storefront-loading {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.storefront-loading-full-page {
    height: calc(100vh - 328px);
}

.ReactModal__Overlay {
    background-color: rgba(0, 0, 0, .8)!important;
}

.ReactModal__Content {
    background: #183159!important;
    border: 2px solid #1675FF!important;
    width: 600px!important;
    color: #FFF!important;
    border-radius: 20px!important;
}

.storefront-modal-img {
    width: 340px;
}

.storefront-modal-title {
    font-size: 22px;
    font-weight: 600;
    padding: 20px;
    border-bottom: 1px solid #1675FF;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.dflx-center {
    display: flex;
    align-items: center;
}

.storefront-modal-content {
    padding: 20px;
    padding-top: 25px;
}

.storefront-modal-description {
    color: #FFF;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: -0.32px;
}

.storefront-product-tags {
    margin-top: 22px;
}

.storefront-product-tags-title {
    letter-spacing: -0.24px;
    font-weight: 500; 
    font-size: 12px;
    color: var(--text-color);
    text-transform: uppercase;
    opacity: .5;
}

.storefront-product-tags-container {
    margin-top: 9px;
    display: flex;
    width: 100%;
}

.storefront-product-tags-container > .storefront-product-tag:first-of-type {
    margin-left: 0;
}

.storefront-product-tag {
    width: fit-content;
    border-radius: 5px;
    margin-left: 10px;
    height: 37px;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: -0.32px;
    background: rgba(255, 255, 255, 0.10); 
    padding: 0 7.5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.storefront-modal-options {
    margin-top: 25px;
    padding-top: 25px;
    border-top: 2.5px solid rgba(22, 117, 255, .5);
}

.storefront-modal-options label, .storefront-modal-options .storefront-quantity-text, .storefront-modal-value-title {
    letter-spacing: -0.24px;
    font-weight: 500; 
    font-size: 12px;
    color: var(--text-color);
    text-transform: uppercase;
    opacity: .5;
}

.storefront-modal-options > .input-group:last-of-type {
    margin-top: 25px;
}

.storefront-modal-input {
    background: transparent;
    border: 2px solid rgba(255, 255, 255, 0.10); 
    color: #fff;
    border-radius: 10px;
    letter-spacing: -0.32px;
    font-size: 16px;
    padding: 8px 12px;
    font-weight: 400;
    line-height: normal;
    width: 100%;
    margin-top: 8px;
}

.storefront-modal-select {
    display: block;
    border: 1px solid var(--theme-color);
    margin-top: 16px;
}

.cart-section-title {
    display: flex;
}

.cart-section-title {
    display: flex;
    align-items: center;
}

.cart-section-title-icon {
    width: 43px;
    height: 43px;
    background: rgba(22, 117, 255, 0.25);
    border-radius: 9.786px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cart-section-title-text {
    font-size: 16px;
    font-weight: 500;
    letter-spacing: -0.32px;
    margin-left: 12px;
}

.storefront-cart-item-options {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
}

.storefront-cart-item-option {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.storefront-cart-item-option-title {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: -0.24px;
    opacity: 0.75;
}

.storefront-cart-item-option-value {
    font-size: 12px;
    letter-spacing: -0.24px;
    font-weight: 500;
}

.storefront-quantity-select {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.storefront-cart-item .storefront-quantity-select {
    margin-right: 12px;
}

.storefront-quantity-control {
    display: flex;
    justify-content: space-between;
}

.storefront-quantity-control input {
    width: 60px;
    background: var(--dark-bg);
    border: none;
    color: #fff;
    padding: 4px;
    text-align: center;
    border-radius: 6px;
}

.ml-8px {
    margin-left: 8px;
}

.storefront-quantity-btn {
    background: var(--dark-bg);
    margin: 0 2px;
    font-weight: 700;
    border-radius: 6px;
}

.storefront-modal-btns {
    display: flex;
    align-items: center;
}

.storefront-modal-btn {
    border-radius: 9.786px; 
    background: var(--theme-color);
    padding: 10px 34px;
    letter-spacing: -0.28px; 
    font-size: 14px; 
    font-weight: 500;
    height: 43px;
}

.smb-lg {
    padding: 10px 62px;
}

.smb-sm {
    margin-left: 10px;
}

.storefront-modal-total {
    display: flex;
    align-items: center;
}

.storefront-modal-txt {
    opacity: 0.75;
    font-weight: 500;
    letter-spacing: -0.32px;
    font-size: 16px;
}

.storefront-modal-price {
    letter-spacing: -0.4px;
    font-weight: 700;
    font-size: 20px;
    margin-left: 8px;
}

.storefront-modal-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px;
    border-top: 1px solid var(--theme-color);
    padding-top: 20px;
}

.storefront-cart-pg {
    width: 100%;
    display: flex;
}

.storefront-cart {
    padding: 0 24px 0 0;
    width: 50%;
}

.storefront-checkout {
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 30%;
    margin-left: 2%;
    height: fit-content;
    border-radius: 8px;
    padding-top: 0;
}

.checkout-input {
    background: var(--dark-bg);
}

.checkout-new-user-checkout-delivery {
    margin-top: 30px;
}

.checkout-new-guest-checkout-delivery {
    margin-top: 30px;
}

.checkout-new-guest-auth-prompt-txt {
    letter-spacing: -0.32px; 
    font-size: 16px; 
    font-weight: 500;
    opacity: 0.75;
}

.checkout-new-guest-auth-btns {
    margin-top: 16px;
}

.checkout-new-guest-auth-btns > a:first-of-type > button {
    padding-left: 0;
    margin-left: 0;
}

.checkout-submit-btn {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0!important;
    height: 48px;
    transition: .25s;
}

.ticket-submit-btn {
    width: fit-content;
    padding: 15px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;
    transition: .25s;
}

.checkout-submit-btn:disabled, .user-balance-order-btn:disabled, .btn-settings-submit:disabled {
    background: rgba(22, 117, 255, .75);
    color: rgba(255, 255, 255, .75);
    transition: .25s;
}

.checkout-new-title {
    color: #FFF;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: -0.32px;
    opacity: 0.75;
}

.checkout-new-input {
    background: transparent;
    border: 2px solid rgba(255, 255, 255, 0.10); 
    color: #fff;
    border-radius: 10px;
    letter-spacing: -0.32px;
    line-height: normal;
    min-width: 340px;
    width: fit-content;
    margin-top: 8px;
    display: flex;
    align-items: center;
}

.checkout-new-input-disabled {
    background: rgba(255, 255, 255, 0.10);
}

.checkout-new-input-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 43px;
}

.checkout-new-input-field {
    padding: 8px 0;
    border: none;
    background: transparent;
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    width: calc(100% - 43px);
}

.checkout-logout {
    margin-left: 17px;
    color: #D51F26;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -0.32px;
    text-decoration: none;
}

.checkout-new-guest-checkout-delivery {
    display: flex;
    align-items: center;
}

.checkout-new-user-checkout-delivery {
    display: flex;
    align-items: center;
}

.checkout-new-user-email {
    display: flex;
    align-items: center;
    margin-top: 8px;
}

.checkout-new-user-email > .checkout-new-input {
    margin-top: 0;
}

.checkout-new-guest-divider {
    color: #FFF;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -0.32px;
    opacity: .1;
    margin: 0 32px;
}

.storefront-cart-title {
    font-size: 22px ;
    font-weight: 700;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid transparent;
    margin: 40px 0;
    margin-top: 0;
}

.sft-title {
    font-size: 24px;
    font-weight: 500;
    letter-spacing: -0.48px;
}

.sft-products {
    color: rgba(255, 255, 255, .75);
    font-weight: 500;
    letter-spacing: -0.48px;
}

.storefront-checkout-title {
    font-size: 22px;
    font-weight: 700;
}

.checkout-inputs {
    padding-top: 0;
    margin-top: 18px;
}

.checkout-inputs > .input-group > input {
    margin-top: 6px;
}

.checkout-inputs label {
    color: rgba(255, 255, 255, 0.6);
}

.storefront-checkout-use-account-balance, .storefront-checkout-payment-method {
    color: rgba(255, 255, 255, 0.6);
}

.storefront-checkout-payment-methods > .storefront-checkout-payment-method:first-of-type {
    margin-top: 6px;
}

.storefront-checkout-payment-method {
    display: flex;
    align-items: center;
    padding: 12px 16px;
    border: 2px solid transparent;
    border-radius: 8px;
    background: var(--dark-bg);
    margin-top: 12px;
    color: #fff;
    justify-content: space-between;
}

.storefront-checkout-payment-method:hover {
    cursor: pointer;
}

.storefront-checkout-payment-method-selected {
    border: 2px solid var(--theme-color);
}

.storefront-checkout-payment-method-image {
    font-size: 26px;
    padding: 3px;
}

.storefront-checkout-payment-methods {
    margin-bottom: 24px;
}

.input-group label {
    margin-bottom: 2px;
}

.checkout-complete {
    justify-self: flex-end;
    margin-top: 18px;
}

.checkout-complete > .auth-form-termstext {
    width: 100%;
    margin-left: 0;
}

.storefront-cart > .storefront-cart-item:first-of-type {
    margin-top: 0;
}

.storefront-cart-item {
    display: flex;
    flex-direction: column;
    padding: 18px;
    border-radius: 10px;
    width: 395px;
    align-items: center;
    margin-top: 18px;
    background: transparent;
    border: 1px solid var(--theme-color);
}

.storefront-cart-item-title {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.storefront-cart-item-option-divider {
    width: 4px;
    height: 4px;
    transform: rotate(45deg);
    flex-shrink: 0;
    background: #1675FF;
    margin: 0 16px;
}

.storefront-cart-item-content {
    display: flex;
    width: 100%;
    align-items: center;
}

.storefront-cart-item-contenxt-txt {
    padding: 18px;
}

.storefront-cart-content-title {
    letter-spacing: -0.32px;
    font-size: 16px;
    font-weight: 400;
}

.storefront-cart-content-subtitle {
    letter-spacing: -0.24px;
    font-weight: 400;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.50);
}

.storefront-cart-image {
    height: 100px;
    border-radius: 8px;
    margin-top: 4px;
    border: 1px solid rgba(255, 255, 255, .05);
}

.ucvi-ic {
    font-size: 42px;
    color: var(--theme-color);
}

.storefront-cart-quantity-btn {
    background: transparent;
    border: none;
}

.storefront-cart-quality-control input {
    background: transparent;
    border: none;
    width: 20px;
}

.storefront-cart-quality-control > .storefront-cart-quantity-btn:first-of-type {
    margin-left: 0;
}

.storefront-cart-quality-control > .storefront-cart-quantity-btn:last-of-type {
    margin-right: 0;
}

.storefront-cart-content {
    width: 68%;
    background: rgba(22, 117, 255, .1);
    border-radius: 10px;
    padding: 25px;
}

.storefront-cart-details {
    padding-bottom: 30px;
    border-bottom: 2px solid var(--theme-secondary-color);
}

.storefront-cart-delivery {
    padding-top: 30px;
    padding-bottom: 30px;
    border-bottom: 2px solid var(--theme-secondary-color);
}

.storefront-cart-payment {
    padding-top: 30px;
}

.storefront-cart-content-quantity {
    font-size: 18px;
    font-weight: 500;
    text-align: right;
    color: rgba(255, 255, 255, 0.5);
    align-self: flex-end;
    margin-top: 12px;
}

.storefront-cart-content-price {
    font-size: 24px;
    font-weight: 500;
    text-align: right;
    color: #fff;
    align-self: flex-end;
}

.storefront-cart-remove-btn {
    margin: 0;
    background: transparent;
    color: #e63757;
    font-size: 22px;
    align-self: flex-end;
    padding: 0;
}

.storefront-cart-remove-btn svg {
    margin-right: 8px;
}

.coupon-summary {
    border: 1px solid var(--theme-color);
    border-radius: 10px;
    padding: 14px;
}

.coupon-summary-title {
    letter-spacing: -0.32px;
    font-size: 16px;
    font-weight: 500;
}

.coupon-input {
    border: 2px solid rgba(255, 255, 255, 0.10); 
    border-radius: 10px; 
    height: 40px;
    display: flex;
    align-content: center;
    margin-top: 12px;
}

.coupon-input-field {
    width: calc(100% - 30px);
    background: none;
    border: none;
    letter-spacing: -0.32px; 
    font-weight: 400;
    font-size: 16px;
    color: rgba(255, 255, 255, .25);
    padding: 0 8px;
}

.coupon-input-applied {
    border: 2px solid #1AD77A;
    align-items: center;
}

.coupon-input-applied > svg {
    margin-left: 9px;
}

.coupon-input-applied > .coupon-input-field {
    color: #fff;
    width: calc(100% - 30px - 28px - 9px);
}

.coupon-input-field:focus::placeholder {
    color: rgba(255, 255, 255, .25);
}

.coupon-input-field:focus {
    color: #fff;
}

.coupon-input-btn {
    width: 80px;
    border: none;
    background: none;
    text-transform: uppercase;
    color: var(--theme-color);
    font-weight: 400;
}

.coupon-input-btn-remove {
    color: #D51F26;
    font-size: 11.429px;
    letter-spacing: -0.229px;
}

.coupon-discount {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    margin-top: 9px;
}

.coupon-discount-title {
    color: rgba(255, 255, 255, 0.50);
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.28px
}

.coupon-discount-value {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.28px;
    margin-left: 6px;
}

.checkout-summary {
    background: rgba(22, 117, 255, 0.10);
    border-radius: 10px;
    border-top: 11px solid var(--theme-color);
    padding: 14px;
    margin-top: 18px;
}

.checkout-summary-title {
    color: var(--text-color);
    font-size: 16px;
    font-weight: 500;
    letter-spacing: -0.32px;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(22, 117, 255, .25);
}

.checkout-summary-content {
    padding: 18px 0;
    border-bottom: 1px solid rgba(22, 117, 255, .25);
}

.checkout-summary-content > .checkout-summary-text:first-of-type {
    margin-top: 0;
}

.checkout-summary-text {
    display: flex;
    justify-content: space-between;
    margin-top: 9px;
}

.checkout-final-summary-text {
    padding: 10px 0;
    font-weight: 500;
}

.grn {
    color: #1AD77A;
}

.checkout-summary-text > div:first-of-type {
    opacity: .75;
}

.checkout-final-summary-text > div:first-of-type {
    opacity: 1;
}

.checkout-summary-text {
    letter-spacing: -0.28px;
    font-weight: 500;
    font-size: 14px;
}

.checkout-total-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.checkout-total-title {
    font-size: 32px;
}

.checkout-total-amount {
    font-size: 700;
    font-size: 32px;
}

.input-group > label {
    padding-bottom: 4px;
}

.checkout-form-captcha {
    display: none;
}

.mt-2 {
    margin-top: 8px;
}

.mt-4 {
    margin-top: 12px;
}

.mt-18 {
    margin-top: 18px;
}

input[type="checkbox"] {
    appearance: none;
    background-color: var(--dark-bg);
    margin: 0;
    font: inherit;
    color: var(--dark-bg);
    width: 1.5em;
    height: 1.5em;
    border: 0.15em solid var(--dark-bg);
    border-radius: 0.15em;
    transform: translateY(-0.075em);
    display: grid;
    place-content: center;
}
  
input[type="checkbox"]::before {
    content: "";
    width: 0.95em;
    height: 0.95em;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em var(--theme-color);
}
  
input[type="checkbox"]:checked::before {
    transform: scale(1);
}

.storefront-checkout-use-account-balance {
    display: flex;
    justify-content: space-between;
    padding-top: 0;
    margin-bottom: 18px;
}

.userorders-table {
    width: 100%;
    border-collapse: separate; 
    border-spacing: 0 .5em;
}

.usertickets-table {
    border-spacing: 0;
}

.userorders-table-lg {
    background: var(--dark-bg);
    padding: 12px 24px;
    border-radius: var(--default-border-radius);
    min-height: 756px;
}

.userorders-table th {
    text-align: left;
    padding: 12px 18px;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: -0.24px;
    font-weight: 500;
    color: rgba(255, 255, 255, .5);
    border-top: 1.103px solid rgba(22, 117, 255, .25);
    border-bottom: 1.103px solid rgba(22, 117, 255, .25);
}

.userorders-table-lg th {
    border-top: none;
}

.userorders-table-lg th {
    padding-bottom: 18px;
}

.userorders-table-status {
    font-size: 14px;
    letter-spacing: -0.28px;
    font-weight: 400;
}

.userorders-table td {
    margin: 0;
    padding: 8px 18px;
    margin-top: 1em;
    text-align: left;
    font-size: 15.5px;
}

.userorders-table > tbody > tr > td:first-of-type {
    border-bottom-left-radius: 6px;
    border-top-left-radius: 6px;
}

.userorders-table tbody > tr button {
    width: 100%;
    color: var(--theme-color);
    width: fit-content;
    background: transparent;
    box-shadow: none;
}

.userorders-table tbody > tr button:hover {
    background: transparent!important;
}

.userorders-table > tbody > tr > td:last-of-type {
    border-bottom-right-radius: 6px;
    border-top-right-radius: 6px;
}

.userorders-table-status-processing {
    color: #1AD77A;
}

.userorders-table-status-awaiting_payment {
    color: #FFCB3D;
}

.userorders-table-status-cancelled {
    color: #D51F26
}

.userorders-table-status-completed {
    color: #1AD77A;
}

.userorder-status {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.userorder-status-box {
    width: fit-content;
    display: flex;
    align-items: center;
    color: #999;
}

.userorder-status-box-content {
    margin-left: 24px;
    display: flex;
    flex-direction: column;
}

.userorder-status-box-icon {
    font-size: 48px;
}

.userorder-status-arrow {
    color: #999;
    font-size: 32px;
}

.userorder-status-box-title {
    font-size: 24px;
    text-transform: uppercase;
    font-weight: 400;
}

.userorder-status-box-text {

}

.userorder-status-box-active {
    animation: flashing .7s ease-out infinite alternate;
    color: rgba(255, 255, 255);
}

.userorder-status-box-final {
    color: #fff;
    opacity: 1;
}

.userorder-payment-status-update {
    padding: 24px;
}

.userorder-payment-status-update-title {
    font-size: 28px;
    font-weight: 700;
}

.userorder-payment-status-update-title svg {
    margin-right: 4px;
}

.userorder-payment-status-update-text {
    font-size: 18px;
}

.userorder-crypto-view-pending-notif {
    background: var(--main-bg);
    padding: 18px;
    border-radius: 8px;
    font-weight: 600;
    margin-top: 6px;
}

.userorder-crypto-view-pending-notif svg {
    margin-right: 4px;
}

.userorder-content {
    background: transparent;
    border: 1px solid rgba(255, 255, 255, .1);
    border-radius: 10px;
    padding: 18px;
    color: rgba(255, 255, 255, .75);
    font-size: 14px;
    font-weight: 400;
    letter-spacing: -0.28px;
    width: 100%;
    height: fit-content;
    margin-top: 12px;
    overflow-y: scroll;
    white-space: "pre-wrap";
}

.btn-psu {
    margin-left: 2px;
    margin-top: 12px;
    border-radius: 8px;
}

.btn-psu svg {
    padding-right: 4px;
}

.capitalize {
    text-transform: capitalize;
}

@keyframes flashing {
  0% {
    color:#fff;
    opacity:.3
  }
  100% {
    color:#fff;
    opacity:1
  }
}
@-webkit-keyframes flashing {
  0% {
    color:#fff;
    opacity:.3
  }
  100% {
    color:#fff;
    opacity:1
  }
}

.userorder-status-box-completed {
    opacity: .8;
}

.userorder-data {
    display: flex;
    width: 100%;
}

.user-completedorder-data {
    display: flex;
    width: 100%;
}

.user-completedorder-products {
    width: 68.5%;
    background: var(--dark-bg);
    padding: 19px 28px;
    padding-bottom: 35px;
    border-radius: 10px;
    height: fit-content;
}

.user-completedorder-info > .checkout-summary:first-of-type {
    margin-top: 0;
}

.user-completedorder-info {
    width: 30%;
    margin-left: 1.5%;
}

.userorder-data-other {
    width: 50%;
    padding: 18px;
    padding-left: 0;
    padding-top: 0;
}

.userorder-data-main {
    width: 50%;
    padding: 18px;
    padding-right: 0;
    padding-top: 0;
}

.order-id-title {
    display: flex;
    align-items: center;
    font-size: 24px;
    letter-spacing: -0.48px;
    font-weight: 500;
}

.order-welcome-title {
    margin: 40px 0;
}

.order-id-title-text {
    margin-left: 8px;
    background: rgba(22, 117, 255, 0.25); 
    border-radius: 10px;
    border: 1px solid var(--theme-color);
    letter-spacing: -0.36px;
    font-size: 18px;
    font-weight: 500;
    height: 31px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    margin-left: 12px;
}

.userorder-product-data {
    background: rgba(22, 117, 255, .1);
    border-radius: 10px;
    padding: 25px;
}

.userorder-data-other-title {
    height: 74px;
    border-bottom: 1px solid rgba(255, 255, 255, .2);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    font-weight: 600;
}

.checkout-payment-area {
    background: var(--dark-bg);
    border-radius: var(--default-border-radius);
    padding: 12px 24px;
    padding-bottom: 24px;
    border-top: 11px solid var(--theme-color);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.checkout-payment-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 7px 0 18px 0;
    border-bottom: 1px solid rgba(22, 117, 255, .25); 
}

.checkout-payment-title-text {
    font-size: 17.645px;
    letter-spacing: -0.353px;
    font-weight: 500;
}

.checkout-crypto-options {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
}

.checkout-crypto-back {
    display: flex;
    align-items: center;
    padding: 12px 16px;
    border: 2px solid transparent;
    border-radius: var(--secondary-border-radius);
    background: var(--dark-bg);
    margin-top: 18px;
    color: #fff;
    color: var(--theme-color);
    transition: .25s;
    transition-property: transform;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
    font-size: 16px;
    width: 100%;
}

.checkout-crypto-option {
    display: flex;
    align-items: center;
    padding: 12px 16px;
    border: 2px solid transparent;
    border-radius: var(--secondary-border-radius);
    background: var(--dark-bg);
    margin-top: 8px;
    margin-right: 8px;
    color: #fff;
    color: var(--theme-color);
    transition: .25s;
    transition-property: transform;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
    font-size: 16px;
    width: fit-content;
}

.checkout-crypto-option svg, .checkout-crypto-back svg {
    margin-right: 8px;
}

.checkout-crypto-option:hover, .checkout-crypto-back:hover {
    border: 2px solid var(--theme-color);
    background: rgba(56, 75, 221, 0.25);
    cursor: pointer;
    transition: .25s;
}

.checkout-crypto-option:hover > .userorder-crypto-option-title {
    color: var(--theme-color);
    transition: .25s;
}

.userorder-data-other-value {
    padding: 18px;
}

.userorder-data-other-value-title {
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: .35px;
    color: rgba(255, 255, 255, .55);
    font-weight: 500;
}

.userorder-data-other-value-content {
    font-size: 24px;
}

.userorder-crypto-select {
    display: flex;
    width: 100%;
    height: 74px;
    border-bottom: 1px solid rgba(255, 255, 255, .2);
}

.userorder-crypto-option {
    display: flex;
    align-items: center;
    padding: 0 20px 0 20px;
    border-bottom: 1px solid transparent;
}

.userorder-crypto-option:hover {
    cursor: pointer;
}

.userorder-crypto-option-img {
    width: 26px;
    height: 26px;
    margin-right: 12px;
}

.userorder-crypto-option-title {
    font-weight: 500;
    color: rgba(255, 255, 255, .75);
    font-size: 14px;
    letter-spacing: -0.262px;
}

.userorder-crypto-option-selected {
    border-bottom: 1px solid #fff;
}

.userorder-crypto-option-selected div {
    color: #fff;
}

.userorder-crypto-view-image {
    border-radius: 12px;
    background: white;
    padding: 18px;
}

.userorder-crypto-view {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 18px;
    border: 1px solid var(--theme-color);
    border-radius: var(--secondary-border-radius);
}

.userorder-crypto-view-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.userorder-crypto-view-title {
    display: flex;
    align-items: center;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: -0.48px;
}

.userorder-crypto-view-title-img {
    margin-right: 12px;
    width: 38px;
}

.userorder-crypto-view-value {
    margin-top: 16px;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.userorder-crypto-view-value-title {
    font-size: 16px;
    letter-spacing: -0.28px; 
    font-weight: 400;
    text-transform: uppercase;
    color: rgba(255, 255, 255, .95);
}

.userorder-crypto-view-value-text {
    font-size: 14px;
    background: var(--main-bg);
    width: fit-content;
    padding: 7px 9px;
    border-radius: 8px;
    color: rgba(255, 255, 255, .65);
    border: 2px solid transparent;
    transition: .25s;
    margin-top: 6px;
}

.userorder-crypto-view-value-text:hover {
    cursor: pointer;
    color: var(--theme-color);
    border: 2px solid var(--theme-color);
    background: rgba(56, 75, 221, 0.25);
    transition: .25s;
}

body {
    position: relative;
    min-height: 100vh;
}

body::after {
    content: "";
    display: block;
    height: 244px;
}

.footer {
    height: fit-content;
    color: #D9DBE1;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: fit-content;
}

.footer-content {
    display: flex;
    width: 100%;
    background: rgba(22, 117, 255, 0.25);
    align-items: center;
    justify-content: space-between;
    border-radius: var(--default-border-radius);
}

.footer-branding {
    width: 100%;
}

.footer-branding-content {
    width: 100%;
    justify-content: center;
    text-align: center;
    align-items: center;
    background: rgba(22, 117, 255, 0.25);
}

.footer-branding-txt {
    width: 100%;
    color: #D9DBE1;
    padding: 16px 0;
    font-size: 16px;
    line-height: 32px;
    flex-wrap: nowrap;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow-x: hidden;
    text-align: center;
}

.footer-social-nav {
    display: flex;
}

.footer-social-logo {
    background: rgba(255, 255, 255, 0.1);
    display: flex;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    font-size: 20px;
    color: #fff;
    text-align: center;
    margin-left: 10px;
    align-items: center;
    justify-content: center;
    margin-left: 8px;
}

.footer-social-logo > img {
    width: 18px;
}

.footer-nav {
    margin: 16px 0;
    display: flex;
    flex: 1;
}

.footer-nav > * {
    color: #D9DBE1;
    text-transform: capitalize;
    font-weight: 600;
    margin: 0 6px;
    text-decoration: none;
}

.nav-right {
    justify-content: flex-end;
}

.footer-btm {
    border-top: 1px solid rgba(255, 255, 255, 0.17);
    padding-top: 30px;
    padding-bottom: 30px;
    margin-top: 30px;
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.cart-empty {
    margin-top: 64px;
}

.cart-empty-icon {
    color: var(--theme-color);
    font-size: 36px;
}

.cart-empty-text {
    font-size: 22px;
    font-weight: 700;
}

.storefront-items {
    min-height: 680px;
}

.user-tickets-question {
    display: flex;
    margin-top: 32px;
}

.user-tickets-question-index {
    background: var(--theme-color);
    border-radius: 50%;
    height: fit-content;
    font-size: 20px;
    font-weight: 600;
    width: 54px;
    height: 54px;
    line-height: 54px;
    text-align: center;
}

.user-tickets-question-content {
    margin-left: 24px;
}

.user-tickets-question-title {
    font-size: 18px;
    letter-spacing: -0.353px;
    font-weight: 500;
    margin-top: 12px;
}

.user-tickets-question-description {
    color: rgba(255, 255, 255, .75);
    font-size: 14px;
    letter-spacing: -0.28px;
}

.user-tickets-question-input {
    background: transparent;
    
    width: 821px;

    border: 2px solid #343C51;
    color: #fff;
    border-radius: 10px;

    letter-spacing: -0.32px;
    font-size: 16px;
    
    padding: 8px 12px;
    font-weight: 400;
    line-height: normal; 
    opacity: 0.35;

    min-height: 40px;
    margin-top: 18px;
}

.user-tickets-question-input-select:hover {
    cursor: pointer;
}

.user-tickets-question-captcha {
    margin-top: 12px;
    margin-bottom: 12px;
}

.btn-smt-ticket {
    margin-left: 0;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    text-align: center;
    align-items: center;
    justify-content: center;
    height: 43px;
    display: flex;
    letter-spacing: normal;
  border-radius: var(--secondary-border-radius);
}

.mt-0 {
    margin-top: 0!important;
}

.userticket-message-content {
    max-width: 48%;
    width: fit-content;
    flex-direction: column;
    display: flex;
}

.userticket-message { 
    width: fit-content;
    padding: 10px 12px;
    border-radius: 6px;
    font-size: 17.5px;
    display: flex;
    flex-direction: column;
}

.userticket-message-container {
    width: 100%;
    display: flex;
    margin-top: 18px;
}

.userticket-message-container-user {
    justify-content: right;
}

.userticket-message-container-user > .userticket-message-content {
    text-align: right;
    align-items: flex-end;
}

.userticket-message-container-staff {
    justify-content: left;
}

.userticket-message-container-staff > .userticket-message-content {
    text-align: left;
    align-items: flex-start;
}
.userticket-message-container {
    width: 100%;
    display: flex;
    margin-top: 18px;
}

.userticket-message-user {
    background: var(--theme-color);
}

.userticket-message-info-text {
    color: rgba(255, 255, 255, .45);
    font-size: 16px;
    margin-top: 2px;
}

.userticket-message-staff {
    background: var(--dark-bg);
}

.userticket-messages {
    min-height: 350px;
}

.ticket-closed-alert {
    background: var(--dark-bg);
    padding: 14px 18px;
    font-size: 19.5px;
    border-radius: 12px;
    margin-top: 32px;
    margin-bottom: 32px;
}

.ticket-closed-alert svg {
    margin-right: 8px;
}

.ticket-closed-alert a {
    color: var(--theme-color);
}

.lnk-txt {
    color: var(--theme-color);
    text-decoration: none;
    font-weight: 500;
}

.lnk-txt:hover {
    text-decoration: underline;
}

.w75 {
    width: 85%;
}

.userticket-send-message {
    background: var(--dark-bg);
    padding: 18px;
    border-radius: 12px;
    margin-top: 36px;
    margin-bottom: 36px;
}

.userticket-send-message textarea {
    background: var(--main-bg);
    width: 100%;
    border: none;
    border-radius: 8px;
    padding: 12px 14px;
    color: #fff;
    margin-top: 12px;
}

.userticket-send-message-options {
    width: 100%;
    display: flex;
    justify-content: right;
    margin-top: 12px;
}

.userticket-send-message-options button {
    margin-right: 0;
}

.userticket-message-title {
    font-size: 20px;
    font-weight: 700;
}

.userticket-message-text {
    font-size: 16px;
    color: rgba(255, 255, 255, .75);
}

.userticket-message > * {
    display: block;
}

.secondary-dark-bg {
    background: var(--dark-bg);
}

.faq-title {
    text-align: center;
    margin-bottom: 54px;
}

.faq-title > .user-home-welcome-title {
    font-size: 34px;
}

.faq-title > .user-home-welcome-text {
    font-size: 16px;
    margin-top: 0;
}

.faq-questions {
    margin-bottom: 128px;
}

.faq-question {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    padding-bottom: 16px;
    background: var(--dark-bg);
    border-radius: 12px;
    padding: 14px;
    border: 1px solid transparent;
}

.faq-question-expanded {
    background: rgba(56, 75, 221, 0.25);
    border: 1px solid var(--theme-color);
}

.faq-question:hover {
    cursor: pointer;
}

.faq-question-title {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
}

.faq-question-content {
    margin-top: 16px;
    font-size: 16px;
}

.faq-question-content a {
    color: var(--theme-color);
    text-decoration: none;
}

.faq-question-name {
    font-size: 16px;
    line-height: 18px;
}

.faq-question-icon {
    font-size: 16px;
}

.btn-nav {
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    text-align: center;
    font-feature-settings: 'salt' on, 'liga' off;
    color: #FFFFFF;
    padding: 10px 15px;
    transition: all linear .2s;
    letter-spacing: normal;
    border-radius: var(--secondary-border-radius);
    background: var(--theme-color);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 43px;
}

.btn-nav-np {
    padding: 0 3px;
}

.btn-nav:not(.btn-nav-cart) svg {
    margin-right: 8px;
}

.btn-nav-secondary {
    background: transparent;
    box-shadow: none;
}

.btn-nav-cart {
    padding: 0;
    background: rgba(22, 117, 255, 0.25);
    color: var(--theme-color);
    font-weight: 600;
    box-shadow: none;
    width: 43px!important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn-nav-cart svg {
    margin-right: 0;
}

.btn-nav-logout {
    padding: 0;
    background: rgba(213, 31, 38, 0.25);
    color: var(--theme-color);
    font-weight: 600;
    box-shadow: none;
    width: 43px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn-nav-small {
    padding: 15px;
    background: var(--theme-bg-color);
}

.btn-nav-small svg {
    margin-right: 0;
}

// .btn-nav-bg {
//     background: rgba(255, 153, 0, 0.53);
//     border: 1px solid#ff9900;
// }

.btn-snb-tr {
    border: 1px solid transparent;
    background: transparent;
    padding: 5px 0;
}

.btn-pag {
    background: var(--dark-bg);
    border: 1px solid transparent;
    margin-left: 8px;
}

.btn-pag-active {
    border: 1px solid var(--theme-color);
    color: var(--text-color);
    background: var(--theme-color);
}

.storefront-pages {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 48px;
}

.text-line {
    margin-top: 12px;
}

.storefront-announcement {
    background: var(--theme-bg-color);
    color: var(--theme-color);
    border: 2px solid var(--theme-color);
    padding: 12px;
    text-align: center;
    border-radius: 8px;
    font-size: 16px;
    // margin-bottom: 18px;
}

.order-alert {
    background: var(--theme-color);
    color: #fff;
    padding: 14px;
    border-radius: 8px;
    font-size: 16px;
    margin-top: 18px;
    display: flex;
    align-items: center;
    border: 2px solid var(--theme-color);
    // margin-bottom: 18px;
}

.order-alert > svg {
    font-size: 38px;
    margin-right: 14px;
}

.order-alert-title {
    font-weight: 700;
    font-size: 18px;
}

.order-alert-text {
    font-size: 16px;
    margin-top: -4px;
}

.order-alert-sm {
    background: var(--theme-bg-color);
    color: var(--theme-color);
    padding: 14px;
    border-radius: 8px;
    font-size: 16px;
    margin-top: 18px;
    display: flex;
    align-items: center;
    // margin-bottom: 18px;
}

.order-alert-scnd {
    background: var(--theme-bg-color);
    color: var(--theme-color);
    border: 2px solid var(--theme-color);
}

.order-info {
    background: var(--dark-bg);
    padding: 18px;
    border-radius: 8px;
    margin-top: 18px;
}

.userorder-crypto-view-smtxt {
    font-size: 14.5px;
    color: rgba(255, 255, 255, .55);
    margin-top: 18px;
}

.order-alert-sm svg {
    font-size: 28px;
    margin-right: 12px;
}

.user-alert {
    margin-bottom: 32px;
    background: var(--dark-bg);
    display: flex;
    align-items: center;
    width: 100%;
    padding: 16px;
    border-radius: 8px;
    justify-content: space-between;
}

.user-alert-icon {
    font-size: 48px;
    height: fit-content;
    display: flex;
}

.user-alert-icon {
    fill: var(--theme-color);
}

.user-alert-content {
    flex: 1;
    margin-left: 16px;
}

.user-alert-title {
    font-size: 22px;
    font-weight: 700;
}

.user-alert-text {
    font-size: 18px;
}

.user-alert {
    justify-self: end;
}

.storefront-product-stock-container {
    width: 100%;
    padding-top: 30px;
}

.storefront-product-stock-container .storefront-cart-content-title {
    letter-spacing: -0.305px;
    font-size: 15.263px;
    font-weight: 400;
    color: rgba(255, 255, 255, .75);
}

.cnt-btn {
    margin-top: 12px;
    width: 35%;
    margin-left: 32.5%;
}

.cnt-code {
    width: 75%;
    margin-left: 12.5%;
    background: var(--dark-bg);
    border-radius: 6px;
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
}

.cnt-cpy-btn {
    width: fit-content;
    margin: 0;
}

.cnt-cpy-btn svg {
    margin-right: 8px;
}

.cnt-code-txt {
    padding: 16px;
    font-size: 16px;
    color: rgba(255, 255, 255, .8);
    text-transform: uppercase;
}

.cnt-step {
    margin-top: 24px;
}

.cnt-step-title {
    font-size: 20px;
    font-weight: 600;
    color: rgba(255, 255, 255, .9);
}

.cnt-step-text {
    color: rgba(255, 255, 255, .8);
}

.cnt-btn svg {
    margin-right: 8px;
}

.ReactModal__Content {
    max-height: 80%;
    overflow-y: scroll;
}

.storefront-navbar-mobile-content {
    margin-top: 18px;
    width: 100%;
}

.storefront-navbar-mobile-toggle {
    display: none;
    font-size: 28px;
    margin-right: 18px;
    justify-content: right;
}

.storefront-navbar-mobile-content {
    display: none;
}

.storefront-navbar-mobile-toggle:hover {
    cursor: pointer;
}

.storefront-navbar-mobile-content a, .storefront-navbar-mobile-content button {
    text-decoration: none;
}

.storefront-navbar-user-options {
    display: flex;
    align-items: center;
}

.storefront-navbar-auth button {
    width: 100%;
}

.userorder-crypto-expand-icon {
    display: none;
}

.mobile-balance-btn {
    margin-left: 0;
    margin-right: 16px;
}

.mobile-user-btn {
    margin-left: 16px;
}

.mobile-login-btn {
    margin-left: 0;
    padding: 10px 0;
}

.mobile-register-btn {
    margin-right: 16px;
}

.mobile-register-btn > button {
    padding: 10px 5px;
}

.storefront {
    margin-right: auto;
    margin-left: auto;
    width: 100%;
    padding-left: 18px;
    padding-right: 18px;
}

.storefront-container {
    margin-bottom: 48px;
}

.storefront-cont {
    margin-bottom: 40px;
}

*:focus {
    outline: none;
}

textarea:focus, input:focus{
    outline: none;
    border-color: inherit;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.orderfront {
    width: 75%;
    margin-left: 12.5%;
}

.disabled-alwayswhite, .disabled-alwayswhite:disabled {
    color: #fff!important;
}

.ReactModal__Overlay--after-open {
    z-index: 20;
}

.user-referral-link {
    background: var(--dark-bg);
    padding: 20px 34px;
    border-radius: var(--default-border-radius);
}

.user-referral-system {
    margin-top: 15px;
    padding-bottom: 37px;
}

.user-referral-system-title {
    font-size: 20px;
    letter-spacing: -0.4px;
    font-weight: 600;
}

.user-referral-system-subtitle {
    letter-spacing: -0.36px;
    font-weight: 500; 
    font-size: 18px;
    color: rgba(255, 255, 255, .75);
    margin-top: 11px;
}

.user-referral-system-reasons {
    margin-top: 9px;
}

.user-referral-stats {
    display: flex;
    width: 100%;
    margin-top: 15px;
}

.user-referral-stat {
    margin-left: 1.5%;
    width: 49.25%;
    background: var(--dark-bg);
    border-radius: var(--default-border-radius);
    padding: 43px 41px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.user-referral-stats > .user-referral-stat:first-of-type {
    margin-left: 0;
}

.user-refferal-stat-title-text {
    letter-spacing: -0.48px;
    font-weight: 500;
    font-size: 24px;
}

.user-referral-stat-value {
    letter-spacing: -0.913px;
    font-weight: 600;
    font-size: 45.643px;
}

.ursv-b {
    color: var(--theme-color);
}

.text-theme {
    color: var(--theme-color);
    font-weight: 500;
}

.user-home-welcome-title {
    letter-spacing: -0.4px; 
    font-weight: 500; 
    font-size: 24px;
}

.storefront-navbar-auth {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.user-balance-order-other {
    display: flex;
    align-items: center;
}

.user-support-pg {
    display: flex;
    width: 100%;
}

.user-support-new {
    width: 49.25%;
    border-top: 11px solid var(--theme-color);
    border-radius: var(--default-border-radius);
    background: var(--dark-bg);
    padding: 12px 24px;
    padding-bottom: 23px;
}

.user-support-new button {
    margin-top: 15px;
}

.user-support-tickets {
    width: 49.25%;
    margin-left: 1.5%;
    border-top: 11px solid transparent;
    border-radius: var(--default-border-radius);
    background: var(--dark-bg);
    padding: 12px 24px;
}

select option {
    color: black!important;
}

.terms-text {
    letter-spacing: -0.32px;
    line-height: 25.097px;
    font-weight: 400;
    font-size: 16px;
    font-feature-settings: 'clig' off, 'liga' off;
    color: rgba(255, 255, 255, .5);
}

.terms-list-title {
    font-size: 20px;
    letter-spacing: -0.4px;
    font-weight: 600;
    font-size: 20px;
    margin: 24px 0;
}

.terms-list-b {
    letter-spacing: -0.32px;
    line-height: 25.097px;
    font-weight: 400;
    font-size: 16px;
    color: rgba(255, 255, 255, .5);
}

.hover-pointer:hover {
    cursor: pointer;
}

@media (min-width: 576px) {
    .storefront {
        max-width: 640px;
    }
}

@media (min-width: 768px) {
    .storefront {
        max-width: 820px;
    }
}

@media (min-width: 992px) {
    .storefront {
        max-width: 1060px;
    }
}

@media (min-width: 1200px) {
    .storefront {
        max-width: 1140px;
    }
}

@media screen and (max-width: 850px) {
    .storefront-index-hero {
        display: flex;
        flex-direction: column;
    }

    .storefront-index-hero-left {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .storefront-index-hero-right {
        width: 100%;
        padding-top: 0;
    }

    .storefront-index-hero-p, .btn lg-white-btn {
        width: 100%;
    }

    .auth-form, .auth-form-blw {
        width: 100%;
    }

    .lg-white-btn {
        width: 100%;
    }

    .orderfront {
        width: 90%;
        margin-left: 5%;
    }

    .user-home-hero-content {
        width: 100%;
    }

    .user-home-recent-orders {
        width: 100%;
        margin-left: 0;
        margin: 32px 0;
    }

    .user-settings-edit-details {
        width: 100%;
    }

    .user-settings-edit-password {
        width: 100%;
        margin-left: 0;
        margin-top: 32px;
        margin-bottom: 32px;
    }

    .footer-social-nav {
        width: 25%;
    }

    .user-home-hero-nav {
        width: 100%;
        margin-left: 0;
    }

    .userhome-hero-item {
        width: 100%;
        margin-left: 0;
    }
    
    .userorder-data-other {
        width: 100%;
        padding: 0;
    }

    .userorder-data-main {
        width: 100%;
        padding: 0;
    }

    .storefront-cart-title {
        align-items: center;
    }

    .storefront-cart-title > .sft-products {
        width: 30%;
    }

    .storefront-navbar-options {
        display: none;
    }

    .userorder-crypto-expand-icon {
        display: block;
    }

    .storefront-index-hero-img {
        width: 67.5%;
    }

    .storefront-search-container {
        display: flex;
        flex-direction: column;
    }

    .storefront-input-container {
        width: 100%;
        margin-left: 0!important;
    }

    .storefront-category-select {
        width: 100%;
        margin-left: 0;
        margin-top: 12px;
    }

    .storefront-items > .storefront-items-row:first-of-type {
        margin-top: 36px;
    }

    .storefront-items-row {
        margin-top: 5px;
        width: 100%;
    }

    .storefront-items {
        min-height: fit-content;
    }

    .storefront-item {
        width: 100%;
        margin-left: 0;
        margin-top: 5px;
    }

    // .storefront {
    //     width: 90%;
    //     margin-left: 5%;
    // }

    .user-home-hero {
        flex-direction: column;
    }

    .userhome-hero-item {
        margin-left: 0;
        width: 100%;
        margin-top: 36px;
    }

    .user-home-welcome-text {
        margin-top: 0;
    }

    .userorder-status {
        flex-direction: column;
        align-items: left;
    }

    .userorder-status-arrow {
        display: none;
    }

    .userorder-status-box {
        align-items: left;
        margin-top: 12px;
    }

    .userorder-data {
        flex-direction: column-reverse;
    }

    .userorder-data-other {
        margin-left: 0;
        margin-top: 32px;
        margin-bottom: 32px;
    }

    .userorder-content {
        width: 100%;
    }

    .user-home-content {
        margin-bottom: 32px;
    }

    .ReactModal__Content {
        width: 98%!important;
        margin-left: 1%!important;
    }

    .storefront-cart-pg {
        flex-direction: column;
    }

    .storefront-checkout {
        height: fit-content;
        border-radius: 16px;
        margin-top: 18px;
    }

    .checkout-logout {
        display: none;
    }

    .storefront-cart-pg {
        height: fit-content;
    }

    .storefront-cart {
        padding: 0;
    }

    .storefront-navbar-right {
        display: flex;
        align-items: center;
    }

    .user-alert {
        flex-direction: column;
        margin-bottom: 0;
    }

    .user-alert-icon {
        display: none;
    }

    .user-alert button {
        margin-top: 12px;
        width: 100%;
    }

    .user-alert-content {
        margin-left: 0;
    }

    .storefront-navbar-mobile-toggle {
        display: flex;
    }

    .storefront-navbar-right {
        display: none;
    }

    .storefront-navbar-mobile-content {
        display: block;
    }
    
    .storefront-navbar-mobile-options {
        text-align: center;
        margin-bottom: 18px;
    }

    .storefront-navbar-user-options {
        display: flex;
        justify-content: space-evenly;
    }

    .storefront-navbar-option {
        display: block;
        width: 100%;
        margin: 10px 0;
    }

    .storefront-navbar-option-active {
        border-bottom: 2px solid transparent;
        color: var(--theme-color);
    }

    .user-referral-stats {
        flex-direction: column;
        margin-top: 0;
    }
    
    .user-referral-stat {
        width: 100%;
        margin-left: 0;
        margin-top: 15px;
    }

    .mt-0 {
        margin-top: 0;
    }

    .user-completedorder-data {
        flex-direction: column;
    }

    .user-completedorder-products {
        width: 100%;
    }

    .storefront-cart-item {
        width: 100%;
    }

    .user-completedorder-info {
        width: 100%;
        margin-left: 0;
        margin-top: 15px;
    }

    .auth-form-page {
        min-height: 80vh;
        height: fit-content;
        align-items: flex-start;
        justify-content: flex-start;
    }

    .signup-page {
        display: flex;
        flex-direction: column;
    }

    .signup-content {
        margin-left: 0;
        margin-top: 20px;
        margin-bottom: 16px;
    }

    .userorder-crypto-view {
        flex-direction: column;
        height: fit-content;
        overflow: hidden;
    }

    .userorder-status-box {
        display: none;
    }

    .userorder-status-box-active {
        display: flex;
    }

    .userorder-crypto-view-image-container {
        margin-top: 60px;
        margin-bottom: 32px;
    }

    .userorder-crypto-view-content {
        padding: 28px;
        width: 100%;
        margin-left: 0;
    }

    .userorder-crypto-option-selected {
        border-bottom: none;
    }

    .userorder-crypto-option-title {
        margin-left: 6px;
        flex: 1;
    }

    .checkout-crypto-option {
        padding: 8px 12px;
    }

    .userorder-crypto-option {
        height: 74px;
    }

    .userorder-crypto-select {
        flex-direction: column;
        height: fit-content;
    }

    .userorder-crypto-option {
        display: none;
        justify-content: space-between;
    }

    .showMobile {
        display: flex;
    }

    .userorder-crypto-view-value-text {
        font-size: 13px;
    }

    .storefront-cart {
        width: 100%;
    }

    .storefront-checkout {
        width: 100%;
    }

    .footer-content {
        display: flex;
        flex-direction: column;
    }

    .storefront-item-button {
        font-size: 12px;
    }

    .storefront-cart-content {
        width: 100%;
    }

    .storefront-checkout {
        width: 100%;
        margin-left: 0;
    }

    .checkout-new-guest-checkout-delivery {
        flex-direction: column;
    }

    .checkout-new-guest-divider {
        margin: 32px;
        opacity: .25;
        font-size: 20px;
    }

    .storefront-checkout {
        margin-bottom: 32px;
    }

    .checkout-new-guest-email {
        width: 100%;
    }

    .checkout-new-input {
        min-width: 100%;
    }

    .userorders-table-lg {
        overflow: scroll;
    }

    .user-orders-header {
        flex-direction: column;
    }

    .user-orders-header-options{
        flex-direction: column;
        margin-top: 15px;
    }

    .user-orders-filter-status-container {
        margin-top: 15px;
    }

    .user-orders-filter-status-text{
        margin-left: 0;
        width: 70px;
    }

    .user-orders-filter-input {
        width: calc(100% - 70px);
    }
    
    .smb-lg {
        padding: 10px 32px;
    }

    .checkout-new-user-checkout-delivery {
        flex-direction: column;
    }

    .signup-content {
        display: none;
    }

    .user-balance-hero {
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }

    .user-balance-total {
        width: 100%;
    }

    .user-balance-order {
        margin-top: 25px;
        width: 100%;
        margin-left: 0;
        flex-direction: column;
        height: fit-content;
    }

    .user-balance-order .user-balance-order-divider {
        display: none;
    }

    .user-balance-order-amount {
        width: 100%;
    }

    .user-balance-order-payment {
        width: 100%;
        margin-top: 18px;
    }

    .user-balance-order-checkout {
        width: 100%;
        margin-top: 18px;
    }

    .user-balance-order-btn {
        width: 100%;
    }

    .user-balance-order-input {
        width: 100%;
    }

    .user-balance-order-payment-method {
        width: 48%;
        margin-left: 4%;
    }

    .user-balance-order-payment-methods > .user-balance-order-payment-method:first-of-type {
        margin-left: 0;
    }

    .storefront-search-container > .storefront-input-container {
        width: 100%;
        margin-left: 0;
    }

    .storefront-search-container > .storefront-input-container:last-of-type {
        margin-top: 15px;
    }
}